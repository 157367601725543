<template>
  <AppContainer id="dashboard" class="my-5">
    <b-row>
      <b-col lg="6" class="my-1">
        <b-form-group
          label="Sort"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select v-model="sortBy" id="sortBySelect" :options="sortOptions" class="w-75">
              <template v-slot:first>
                <option value>-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          label="Initial sort"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="initialSortSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="sortDirection"
            id="initialSortSelect"
            size="sm"
            :options="['asc', 'desc', 'last']"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          label="Filter"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Type to Search"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          label="Filter On"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          description="Leave all unchecked to filter on all data"
          class="mb-0"
        >
          <b-form-checkbox-group v-model="filterOn" class="mt-1">
            <b-form-checkbox value="movie_id">MID</b-form-checkbox>
            <b-form-checkbox value="title">Title</b-form-checkbox>
            <b-form-checkbox value="published">Published</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>

      <b-col sm="5" md="6" class="my-1">
        <b-form-group
          label="Per page"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table
      show-empty
      small
      stacked="md"
      :items="movies"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <template v-slot:cell(actions)="row">
        <b-button
          size="sm"
          @click="row.toggleDetails"
          class="mr-1"
        >{{ row.detailsShowing ? 'Hide' : 'Show' }} Details</b-button>
        <b-button
          @click="onPublish(row.item)"
          size="sm"
          class="mr-1"
          :variant="row.item.published == 1 ? 'danger' : 'success'"
        >{{ row.item.published == 1 ? 'Unpublish' : 'Publish' }}</b-button>
        <b-button size="sm" variant="primary" :to="'/movies/edit/'+row.item.movie_id">Edit</b-button>
      </template>

      <template v-slot:row-details="row">
        <b-card>
          <b-img-lazy
            v-bind="{width: 200, height: 300}"
            thumbnail
            fluid
            :src="row.item.imgurl"
            alt="Center image"
          ></b-img-lazy>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
            <li>
              <b-button variant="link" @click="onAddShow(row.item)">Add To Shows</b-button>
            </li>
          </ul>
        </b-card>
      </template>
    </b-table>
  </AppContainer>
</template>

<script>
const AppContainer = () => import("@/core/Components/App/AppContainer.vue");
import { api } from "../../resource";
export default {
  name: "dashboard",
  data() {
    return {
      movies: [],
      fields: [
        {
          key: "movie_id",
          label: "MID",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "title",
          label: "Movie Title",
          sortable: true,
          class: "text-center"
        },
        {
          key: "published",
          label: "is Published",
          formatter: value => {
            return value == 1 ? "Yes" : "No";
          },
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true
        },
        { key: "actions", label: "Actions" }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        movie_id: "info-modal",
        title: "",
        description: ""
      }
    };
  },
  created() {
    api.create("movies/all", {}).then(res => {
      this.movies = res.data.movies;
      this.totalRows = this.movies.length;
    });
  },
  methods: {
    onPublish(movie) {
      api.create("movies/publish", movie).then(() => {
        movie.published = movie.published == 1 ? 0 : 1;
      });
    },
    onAddShow(movie) {
      api
        .create("shows/create", movie)
        .then(() => {
          this.$swal("Success", "Added to shows Successfully!", "Success");
        })
        .catch(e => {
          this.$swal("Failure", e.body.status_message, "error");
        });
    },
    onFiltered(filteredMovies) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredMovies.length;
      this.currentPage = 1;
    }
  },
  components: {
    AppContainer
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  }
};
</script>
